<template>
    <validation-observer ref="form" v-slot="{ invalid }" slim>
        <form @submit.prevent="onSubmit" v-bind:class="{'filled': isFilled && !invalid}" class="footer-top__form footer-form">
            <div class="footer-form__status-message" v-if="isSubmitted">
                Заявка успешно отправлена
            </div>
            <validation-provider ref="name" :name="localized.fields.name" rules="required" tag="div" slim
                                 v-slot="{ errors }">
                <div class="form-group footer-form__field" v-bind:class="{'error': errors.length}">
                    <input type="text" autocomplete="off" name="name" v-model="name" id="name"
                           class="form-group__input" placeholder=" ">
                    <span class="error-message">{{ errors[0] }}</span>
                    <label for="name" class="form-group__label">
                        {{ localized.fields.name }} *
                    </label>
                </div>
            </validation-provider>
            <validation-provider ref="phone" :name="localized.fields.phone" rules="required" tag="div" slim
                                 v-slot="{ errors }">
                <div class="form-group footer-form__field" v-bind:class="{'error': errors.length}">
                    <input type="tel" autocomplete="off" name="phone" v-model="phone" id="phone"
                           v-mask="'+7 (###) ###-##-##'"
                           class="form-group__input" placeholder=" ">
                    <span class="error-message">{{ errors[0] }}</span>
                    <label for="phone" class="form-group__label">
                        {{ localized.fields.phone }} *
                    </label>
                </div>
            </validation-provider>
            <validation-provider ref="email" :name="localized.fields.email" rules="email" tag="div" slim
                                 v-slot="{ errors }">
                <div
                    class="form-group footer-form__field footer-form__field_wide"
                    v-bind:class="{'error': errors.length}">
                    <input type="text" autocomplete="off" name="email" v-model="email" id="email"
                           class="form-group__input" placeholder=" ">
                    <span class="error-message">{{ errors[0] }}</span>
                    <label for="email" class="form-group__label">
                        {{ localized.fields.email }}
                    </label>
                </div>
            </validation-provider>
            <div class="form-group footer-form__field footer-form__field_wide">
                 <textarea name="message" id="message" v-model="message" class="form-group__input"
                           placeholder=" " rows="5"></textarea>
                <label for="message" class="form-group__label">
                    {{ localized.fields.message }}
                </label>
            </div>
            <div class="form-submit footer-form__submit">
                <div class="form-submit__text" v-html="localized.agreement"></div>
                <vue-recaptcha size="invisible" ref="recaptcha" @verify="onRecaptureVerify"
                               :sitekey="recapture.key"></vue-recaptcha>
                <button type="submit" :disabled="invalid || !isFilled" class="form-submit__btn">
                    {{ localized.submit_button }}
                </button>
            </div>
        </form>
    </validation-observer>
</template>

<style scoped lang="scss">
.form-group {
    .error-message {
        display: block;
    }
}
</style>

<style>
.form-submit__text p {
    padding-bottom: 10px;
}

.grecaptcha-badge {
    display: none;
    visibility: hidden;
}
</style>
<script>

import axios from 'axios';
import LaravelServerSideValidation from "../mixins/laravel-server-side-validation";
import VueTheMask from 'vue-the-mask'
import {VueRecaptcha} from 'vue-recaptcha';

export default {
    props: [
        'route',
        'csrf_token',
        'lang',
        'recaptcha_site_key'
    ],
    mixins: [
        LaravelServerSideValidation
    ],
    components: {
        VueTheMask,
        VueRecaptcha
    },
    data: function () {
        const lang = JSON.parse(this.lang);

        return {
            name: '',
            phone: '',
            email: '',
            message: '',
            localized: {
                fields: {
                    name: lang.fields?.name ?? 'views/global/components/footer.form.fields.name',
                    phone: lang.fields?.phone ?? 'views/global/components/footer.form.fields.phone',
                    email: lang.fields?.email ?? 'views/global/components/footer.form.fields.email',
                    message: lang.fields?.message ?? 'views/global/components/footer.form.fields.message'
                },
                agreement: lang?.agreement ?? 'views/global/components/footer.form.agreement',
                submit_button: lang?.submit_button ?? 'views/global/components/footer.form.submit_button'
            },
            isSubmitted: false,
            recapture: {
                key: this.recaptcha_site_key,
                response: null
            }
        }
    },
    computed: {
        isFilled() {
            return this.name !== '' && this.phone !== ''
        }
    },
    methods: {
        onRecaptureVerify(response) {
            this.recapture.response = response;
            this.submitForm();
        },
        onSubmit() {
            this.isSubmitted = false;
            this.$refs.form.validate().then((result) => {
                if (!result) {
                    return;
                }

                this.$refs.recaptcha.execute();
            });
        },
        submitForm() {
            const data = this.prepareFormData();
            axios.post(this.route, data).then(
                () => {
                    this.isSubmitted = true;
                    this.clearForm();
                }
            ).catch((err) => {
                this.$renderServerSideValidationErrors(err)
            })
        },
        clearForm() {
            this.name = '';
            this.phone = '';
            this.email = '';
            this.message = '';
            requestAnimationFrame(() => {
                this.$refs.form.reset();
            });
        },
        prepareFormData() {
            const formData = new FormData;
            formData.append('_token', this.csrf_token);
            formData.append('g-recaptcha-response', this.recapture.response);
            formData.append('name', this.name);
            formData.append('phone', this.phone);
            formData.append('email', this.email);
            formData.append('message', this.message);
            formData.append('url', window.location.href);
            formData.append('title', document.title);
            return formData;
        },
    },
    mounted() {

    }
}
</script>
